import * as React from "react";

import { Container, Divider } from "@react/components";
import HowItWorks from "./components/otc/HowItWorks";
import TermsAndFees from "./components/otc/TermsAndFees";
import TradingServices from "./components/otc/TradingServices";

export default function OtcHero(props) {
  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      <Container maxSize="18">
      <div className="s-paddingTop2" style={{ backgroundColor: "#EEEEEE" }} />
        <TradingServices />
        <Divider />
        <HowItWorks
          newOtcTradeUrl={props.newOtcTradeUrl}
          registerUrl={props.registerUrl}
        />
        <Divider />
        <TermsAndFees />
        <div className="s-paddingTop2" style={{ backgroundColor: "#EEEEEE" }} />
      </Container>
    </div>
  );
}
