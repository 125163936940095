import * as React from "react";
import { useRef } from "react";
import Tippy from "@tippyjs/react";
import { RangeInput, RangeInputProps } from "@react/components";

export interface RangeInputWithTooltipProps extends RangeInputProps {
  tooltipContent?: React.ReactNode;
}

const RangeInputWithTooltip: React.FunctionComponent<RangeInputWithTooltipProps> = (
  rangeInputProps
) => {
  // Tippy can't attach itself to disabled element, so we use a wrapper instead
  const rangeInputWrapperRef = useRef();

  // Reference to UI sizes to avoid magic string
  const rangeInputHandleWidth = 20;
  const rangeInputHandleHeight = 20;
  const wrapperPaddingTop = 32;
  const tippyTopVisualAdjustment = 8;

  // Calculate tippy position based on the wrapper dimensions and the input
  // width divided by input progress
  const getReferenceClientRect = () => {
    const rangeInputWrapperElement = rangeInputWrapperRef.current as any;
    if (rangeInputWrapperRef && rangeInputWrapperElement) {
      const value = Number(rangeInputProps.value);
      const min = Number(rangeInputProps.min);
      const max = Number(rangeInputProps.max);
      const progress = ((value - min) / (max - min)) * 100;
      const {
        left,
        top,
        width,
      } = rangeInputWrapperElement.getBoundingClientRect();
      return {
        width: rangeInputHandleWidth,
        height: rangeInputHandleHeight,
        left: left + ((width - rangeInputHandleWidth) / 100) * progress,
        top: top + wrapperPaddingTop + tippyTopVisualAdjustment,
      } as DOMRect;
    }
  };

  // Magic
  // TODO: Normalize this into a Tooltip component
  const tippyParams = {
    theme: rangeInputProps.disabled
      ? "coinlist-tippy-pink-disabled"
      : "coinlist-tippy-pink",
    content: rangeInputProps.tooltipContent || rangeInputProps.value,
    showOnCreate: true,
    hideOnClick: false,
    trigger: "manual",
    getReferenceClientRect: () => getReferenceClientRect(),
  };

  return (
    <Tippy {...tippyParams}>
      <div className="s-paddingTop2" ref={rangeInputWrapperRef}>
        <RangeInput {...rangeInputProps} />
      </div>
    </Tippy>
  );
};

export default RangeInputWithTooltip;
