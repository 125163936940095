import * as React from "react";
import { Container, Grid, Col } from "@react/components";

interface GridSectionProps {
  // props
}

const GridSection: React.FunctionComponent<GridSectionProps> = (
  {
    // props
  }
) => {
  const renderGrid = (spacing) => {
    return (
      <Grid spacing={spacing}>
        <Col xs="24">
          <div className="s-border0_5 s-marginTop1 u-textAlignCenter">24</div>
        </Col>
        {[...Array(2)].map((e, i) => (
          <Col xs="12">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">12</div>
          </Col>
        ))}
        {[...Array(3)].map((e, i) => (
          <Col xs="8">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">8</div>
          </Col>
        ))}
        {[...Array(4)].map((e, i) => (
          <Col xs="6">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">6</div>
          </Col>
        ))}
        {[...Array(6)].map((e, i) => (
          <Col xs="4">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">4</div>
          </Col>
        ))}
        {[...Array(12)].map((e, i) => (
          <Col xs="2">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">2</div>
          </Col>
        ))}
        {[...Array(24)].map((e, i) => (
          <Col xs="1">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">1</div>
          </Col>
        ))}
      </Grid>
    );
  };

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Grid
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of our grid and layout system
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Container</h3>
        <p className="s-marginBottom0_5 u-colorGray6">
          Each page typically has one container, which ensure default page
          padding
        </p>
        <pre>
          <Container>
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">
              Contained
            </div>
          </Container>
        </pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Container: MaxSize
        </h3>
        <p className="s-marginBottom0_5 u-colorGray6">
          You can define the max size (ie: width) of a container
        </p>
        <pre>
          <Container maxSize="12">
            <div className="s-border0_5 s-marginTop1 u-textAlignCenter">
              Contained
            </div>
          </Container>
        </pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <p className="s-marginBottom0_5 u-colorGray6">
          For grid to be fixed everywhere, use xs:
        </p>
        <pre>
          {renderGrid("default")}
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Responsive
        </h3>
        <p className="s-marginBottom0_5 u-colorGray6">
          If you wish to have responsive layout, you can use
          <code className="c-code c-code--inline">xs</code> {"(<768px)"},
          <code className="c-code c-code--inline">sm</code> {"(>=768px)"},
          <code className="c-code c-code--inline">md</code> {"(>=970px)"},
          <code className="c-code c-code--inline">lg</code> {"(>=1170px)"}
        </p>
        <p className="s-marginBottom0_5 u-colorGray6">
          Resize your window in order to see the changes
        </p>
        <pre>
          <Grid>
            <Col xs="24" sm="12" md="8" lg="6">
              <div className="s-border0_5 s-marginTop1 u-textAlignCenter">
                <span className="u-hiddenSmUp">xs="24" </span>
                <span className="u-hiddenXsOnly u-hiddenMdUp">sm="12" </span>
                <span className="u-hiddenXsOnly u-hiddenSmOnly u-hiddenLgOnly">
                  md="8"{" "}
                </span>
                <span className="u-hiddenXsOnly u-hiddenSmOnly u-hiddenMdOnly">
                  lg="6"
                </span>
              </div>
            </Col>
          </Grid>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Col Push & Pull
        </h3>
        <pre>
          <Grid>
            <Col xs="12" pushXs="12">
              <div className="s-border0_5 s-marginTop1 u-textAlignCenter">
                col12 + push12
              </div>
            </Col>
          </Grid>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Grid spacing: 0
        </h3>
        <p className="s-marginBottom0_5 u-colorGray6"></p>
        <pre>
          {renderGrid("0")}
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Grid spacing: C
        </h3>
        <p className="s-marginBottom0_5 u-colorGray6"></p>
        <pre>
          {renderGrid("C")}
        </pre>
      </div>
    </div>
  );
};

export default GridSection;
