import * as React from "react";
import { useState } from "react";

import { Button, Col, Divider, Grid, Label, Link } from "@react/components";

import Flex from "@react/components/Flex";
import LinksContainer from "./LinksContainer";
import MinimalLineChart from "@react/views/shared/MinimalLineChart";
import Typography from "@react/components/typography/Typography";

import { ColorBrandedBorderBox } from "@react/views/shared/ColorBrandedBorderBox";

import { COLOR_MAP } from "@react/utils/color";
import { createLinkObjects } from "@react/utils";
import { getFormattedTimeDay } from "@react/utils/date";

const TIME_FRAME_1D = "1D";
const TIME_FRAME_1W = "1W";
const TIME_FRAME_1M = "1M";

const bannerStyle = {
  backgroundColor: COLOR_MAP.primary,
  borderRadius: 3,
  fontWeight: 600,
  paddingLeft: "4px",
  paddingRight: "4px",
} as any;

export interface CardProps {
  buyTokenUrl?: string;
  dayChartData?: any;
  weekChartData?: any;
  monthChartData?: any;
  coinGeckoId?: string;
  coinGeckoSymbol?: string;
  description: string;
  fromTeam: boolean;
  index: number;
  logo: any;
  prices: any;
  projectLinks: any;
  projectName: string;
  sessionId?: string;
  small?: boolean;
  smallWithSideBar?: boolean;
  tokenProfile: any;
  websiteUrl: string;
  userId?: string;
}

export const ProjectCard: React.FunctionComponent<CardProps> = ({
  buyTokenUrl = "",
  dayChartData = {},
  weekChartData = {},
  monthChartData = {},
  coinGeckoId,
  coinGeckoSymbol,
  description,
  fromTeam = false,
  logo = {},
  prices,
  projectLinks = {},
  projectName,
  sessionId,
  tokenProfile = {},
  websiteUrl,
  userId,
}) => {
  const createChartData = (timeFrame) => {
    const priceData =
      timeFrame === TIME_FRAME_1D
        ? dayChartData.prices
        : timeFrame === TIME_FRAME_1W
        ? weekChartData.prices
        : monthChartData.prices;
    if (!priceData) {
      return {};
    }
    const newGraphData = {
      labels: [],
      datasets: [
        {
          data: [],
        },
      ],
    };
    const updateData = (i) => {
      const labelDate = getFormattedTimeDay(new Date(priceData[i][0]));
      const price = priceData[i][1];
      newGraphData.labels.push(labelDate);
      newGraphData.datasets[0].data.push(price.toString());
    };
    /* Smooth out data */
    if (timeFrame === TIME_FRAME_1D) {
      for (let i = 0; i < priceData.length; i += 11) {
        updateData(i);
      }
    } else if (timeFrame === TIME_FRAME_1W) {
      for (let i = 0; i < priceData.length; i += 23) {
        updateData(i);
      }
    } else {
      for (let i = 0; i < priceData.length; i++) {
        updateData(i);
      }
    }
    /* Make sure last data point is always included */
    const lastIndexNewGraphData = newGraphData.datasets[0].data.length - 1;
    if (
      newGraphData.datasets[0].data[lastIndexNewGraphData] !==
      priceData[priceData.length - 1][1]
    ) {
      updateData(priceData.length - 1);
    }
    return newGraphData;
  };

  const getPercentageChange = (graphData) => {
    if (!graphData.datasets) {
      return 0;
    }
    const prices = graphData.datasets[0].data;
    const originalPrice = prices[0];
    const lastPrice = prices[prices.length - 1];
    return (((lastPrice - originalPrice) / originalPrice) * 100).toFixed(2);
  };

  const [expanded, setExpanded] = useState(false);
  const [timeFrame, setTimeFrame] = useState(TIME_FRAME_1D);
  const [graphData, setGraphData] = useState(() => createChartData(timeFrame));
  const [percentChange, setPercentChange] = useState(() =>
    getPercentageChange(graphData)
  );

  const handleTimeFrameChange = (timeFrame) => {
    const newGraphData = createChartData(timeFrame);
    const percentChange = getPercentageChange(newGraphData);
    setTimeFrame(timeFrame);
    setGraphData(newGraphData);
    setPercentChange(percentChange);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <ColorBrandedBorderBox>
      {fromTeam && (
        <Flex container justifyContent="flex-end">
          <div style={bannerStyle}>
            <Typography
              center
              color="white"
              fontSize={12}
              style={{ display: "inline" }}
            >
              Highlighted by the {tokenProfile.symbol.toUpperCase()} Team
            </Typography>
          </div>
        </Flex>
      )}
      <Flex container spacing={2} style={{ padding: 24 }}>
        {/* HEADER */}
        <Flex alignItems={coinGeckoSymbol ? "flex-start" : "center"} container>
          <img
            src={logo ? logo.default : ""}
            style={{
              borderRadius: "50%",
              marginLeft: "-5px",
              marginRight: 5,
              width: 50,
            }}
          />
          <div className="u-cursorPointer">
            <Typography
              bold
              onClick={() => (window.location.href = websiteUrl)}
              style={{ marginLeft: 4 }}
            >
              <div>{projectName}</div>
            </Typography>
            {coinGeckoSymbol && (
              <div style={{ fontSize: 14, marginLeft: 4 }}>
                ${coinGeckoSymbol.toUpperCase()}
              </div>
            )}
          </div>
        </Flex>
        <div>
          <Typography>{description}</Typography>
        </div>
        <LinksContainer
          fontSize={20}
          handleExpand={handleExpand}
          links={createLinkObjects(projectLinks)}
          page={tokenProfile.symbol.toUpperCase() + " Protocol Page"}
          priceData={coinGeckoId ? prices && prices[coinGeckoId] : null}
          project_name={projectName}
          user_id={userId}
          session_id={sessionId}
          padding={4}
        />
        {expanded && <Divider />}
        {expanded && (
          <div style={{ width: "100%" }}>
            <Grid>
              <Col xs={"12"}>
                <Label>Price</Label>
                <div className="s-fontSize14">
                  <div className="u-fontWeight700 u-displayInline">
                    ${prices[coinGeckoId].usd.toFixed(2)}
                  </div>
                  <div
                    className={`u-displayInline s-marginLeft0_5 ${
                      percentChange > 0 ? "u-colorGreen" : "u-colorRed"
                    }`}
                  >
                    {percentChange > 0 ? "+" : ""}
                    {percentChange}%
                  </div>
                </div>
              </Col>
              <Col xs={"12"}>
                <Flex container justifyContent="flex-end" spacing={1}>
                  <Link
                    cursorPointer
                    fontSize={12}
                    fontWeight={timeFrame === TIME_FRAME_1D ? 700 : 500}
                    onClick={() => handleTimeFrameChange(TIME_FRAME_1D)}
                    unstyled={timeFrame === TIME_FRAME_1D}
                  >
                    {TIME_FRAME_1D}
                  </Link>
                  <Link
                    cursorPointer
                    fontSize={12}
                    fontWeight={timeFrame === TIME_FRAME_1W ? 700 : 500}
                    onClick={() => handleTimeFrameChange(TIME_FRAME_1W)}
                    unstyled={timeFrame === TIME_FRAME_1W}
                  >
                    {TIME_FRAME_1W}
                  </Link>
                  <Link
                    cursorPointer
                    fontSize={12}
                    fontWeight={timeFrame === TIME_FRAME_1M ? 700 : 500}
                    onClick={() => handleTimeFrameChange(TIME_FRAME_1M)}
                    unstyled={timeFrame === TIME_FRAME_1M}
                  >
                    {TIME_FRAME_1M}
                  </Link>
                </Flex>
              </Col>
            </Grid>
            <MinimalLineChart data={graphData} />
            {buyTokenUrl && (
              <div>
                <Button
                  fullWidth
                  onClick={() => window.open(buyTokenUrl, "_blank").focus()}
                >
                  Buy {coinGeckoSymbol ? coinGeckoSymbol.toUpperCase() : ""}
                </Button>
              </div>
            )}
          </div>
        )}
      </Flex>
    </ColorBrandedBorderBox>
  );
};
