import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { Button, TextInput, Spacer } from "@react/components";
import BlockField from "@react/views/shared/forms/BlockField/BlockField";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";
import Typography from "@react/components/typography/Typography";
import Loading, { RequestData } from "@react/components/Loading";

import { RequestType } from "@react/utils/network";

interface Company {
  companyName: string;
}

interface Errors {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  ownerUserId?: string;
}

interface GenerateEntityProps {
  createEntityPostUrl: string;
}

interface Person {
  firstName: string;
  lastName: string;
}

enum EntityType {
  Company = "company",
  Person = "person",
}

const useStyles = makeStyles((theme: any) => ({
  dialogPaper: {
    height: 600,
    maxWidth: 800,
    width: "100%",
  },
}));

export default function GenerateEntity(props: GenerateEntityProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  /* Currently always true, but may want to change in future */
  const [addOwner, setAddOwner] = useState(true);
  const [company, setCompany] = useState<Company>(null);
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState(false);
  const [ownerUserId, setOwnerUserId] = useState("");
  const [person, setPerson] = useState<Person>(null);
  const [postResponse, setPostResponse] = useState(null);
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [type, setType] = useState(EntityType.Company);
  const resetState = () => {
    setType(EntityType.Company);
    setPostResponse(null);
    setPerson(null);
    setOwnerUserId("");
    setLoading(false);
    setErrors({});
    setCompany(null);
    setAddOwner(true);
  };

  const RADIO_BUTTONS = [
    {
      label: "Company",
      radio: { value: EntityType.Company },
    },
    {
      label: "Person",
      radio: { value: EntityType.Person },
    },
  ];

  const hasErrors = () => {
    const errors: Errors = {};
    /* May want to change this in the future */
    if (!ownerUserId) {
      errors.ownerUserId = "Please enter a user ID for the owner";
    }
    if (type === EntityType.Company) {
      if (!company || !company.companyName) {
        errors.companyName = "Please enter a company name";
      }
    } else {
      if (!person || !person.firstName) {
        errors.firstName = "Please enter a first name";
      }
      if (!person || !person.lastName) {
        errors.lastName = "Please enter a last name";
      }
    }
    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Generate Entity</Button>
      <Dialog
        classes={{
          paper: classes.dialogPaper,
        }}
        fullWidth
        onClose={() => setOpen(false)}
        open={open}
      >
        (
        <div>
          <DialogContent>
            <Typography style={{ paddingBottom: 15 }} type={"h2"}>
              Generate Entity
            </Typography>
            <Loading
              handleResponse={(response) => setPostResponse(response)}
              loading={loading}
              requestData={requestData}
            />
            {postResponse ? (
              <div style={{ textAlign: "center" }}>
                <div>
                  {postResponse.data.successMessage ||
                    postResponse.data.errors.message}
                </div>
                <div className="s-marginTop1_5">
                  <Button
                    onClick={() => {
                      if (
                        postResponse.data &&
                        postResponse.data.successMessage
                      ) {
                        resetState();
                      }
                      setPostResponse(null);
                    }}
                  >
                    {postResponse.data && postResponse.data.successMessage
                      ? "Create another entity"
                      : "Try again"}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                {!loading && (
                  <div>
                    <BlockField label="Type of Entity">
                      <RadioCollections
                        radioOptions={RADIO_BUTTONS}
                        name="entity-type"
                        value={type}
                        onChange={(e) => setType(e.target.value as EntityType)}
                      />
                    </BlockField>
                    <Spacer />
                    {type === EntityType.Company ? (
                      <BlockField
                        error={errors.companyName}
                        label={"Company Name"}
                      >
                        <TextInput
                          value={(company && company.companyName) || ""}
                          onChange={(e) =>
                            setCompany({
                              ...company,
                              companyName: e.currentTarget.value,
                            })
                          }
                          placeholder="CoinList"
                        />
                      </BlockField>
                    ) : (
                      <div>
                        <BlockField error={errors.lastName} label={"Last Name"}>
                          <TextInput
                            value={(person && person.lastName) || ""}
                            onChange={(e) =>
                              setPerson({
                                ...person,
                                lastName: e.currentTarget.value,
                              })
                            }
                            placeholder="Keto"
                          />
                        </BlockField>
                      </div>
                    )}
                    <Spacer />
                    <BlockField error={errors.lastName} label={"Last Name"}>
                      <TextInput
                        value={(person && person.lastName) || ""}
                        onChange={(e) =>
                          setPerson({
                            ...person,
                            lastName: e.currentTarget.value,
                          })
                        }
                        placeholder="Keto"
                      />
                    </BlockField>
                    <Spacer />
                    {addOwner && (
                      <BlockField
                        error={errors.ownerUserId}
                        label={"Parent User ID"}
                        hint="The newly created entity will be an entity under this owner."
                      >
                        <TextInput
                          value={ownerUserId}
                          onChange={(e) =>
                            setOwnerUserId(e.currentTarget.value)
                          }
                          placeholder="903fda728afefa1bbax446ff25ad991"
                        />
                      </BlockField>
                    )}
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          {!postResponse && (
            <DialogActions>
              <Button
                onClick={() => {
                  if (!hasErrors()) {
                    const data =
                      type === EntityType.Company
                        ? { ...company, ownerUserId, type }
                        : { ...person, ownerUserId, type };
                    setRequestData({
                      data: data,
                      type: RequestType.POST,
                      url: props.createEntityPostUrl,
                    });
                    setLoading(true);
                  }
                }}
                style={{ bottom: 16, position: "absolute", right: 16 }}
              >
                Create Entity
              </Button>
            </DialogActions>
          )}
        </div>
      </Dialog>
    </div>
  );
}
