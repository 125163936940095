import * as React from "react";

export interface TableRowProps {
  highlighted?: boolean;
  padding?: number;
  children?: React.ReactNode;
}

export const TableRow: React.FunctionComponent<TableRowProps> = ({
  children,
  highlighted = false,
  padding = null,
}) => {
  let style: any = {};
  if (highlighted) {
    style = { backgroundColor: "#FFF4FA" };
  }
  if (padding) {
    style.padding = padding;
  }
  return <tr style={style}>{children}</tr>;
};

export default TableRow;
