import * as React from "react";
import { Grid, Col, Link, Icon, Button } from "@react/components";
import GovernanceProposalShowSettings from "@react/views/admin/governance/proposals/show/settings";
import GovernanceProposalShowContent from "@react/views/admin/governance/proposals/show/content";
import GovernanceProposalShowResults from "@react/views/admin/governance/proposals/show/results";
import GovernanceProposalShowEglResults from "@react/views/admin/governance/proposals/show/egl_results";

export interface GovernanceProposalShowProps {
  protocol: any;
  proposal: any;
  governance_protocol_path: string;
  governance_proposal_path: string;
  governance_path: string;
  admin_governance_protocol_path: string;
  admin_governance_proposal_path: string;
  admin_governance_path: string;
  admin_path: string;
  results: Object;
}

export const GovernanceProposalShow: React.FunctionComponent<GovernanceProposalShowProps> = ({
  protocol,
  proposal,
  governance_protocol_path,
  governance_proposal_path,
  governance_path,
  admin_governance_protocol_path,
  admin_governance_proposal_path,
  admin_governance_path,
  admin_path,
  results,
}) => {
  return (
    <Grid>
      <Col sm="24">
        <h1 className="s-marginBottom3 s-borderBottom2">
          <Link href={admin_path}>
            <span className="u-whiteSpaceNowrap">Admin</span>
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            <Icon icon="chevron-right" />
          </span>
          <Link href={admin_governance_path}>
            <span className="u-whiteSpaceNowrap">Governance</span>
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            <Icon icon="chevron-right" />
          </span>
          <Link href={admin_governance_protocol_path}>
            <span className="u-whiteSpaceNowrap">
              {protocol.token_profile.name}
            </span>
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            <Icon icon="chevron-right" />
          </span>
          <span>{proposal.title}</span>
          <span className="u-floatRight">
            <Link href={governance_proposal_path} unstyled target="_blank">
              <Button variant="secondary" size="small">
                Proposal page
              </Button>
            </Link>
          </span>
        </h1>
      </Col>
      <Col sm="24">
        <div className="s-marginBottom3">
          <h3 className="s-marginBottom1 s-borderBottom1 u-colorGray2">
            Settings
          </h3>
          <GovernanceProposalShowSettings proposal={proposal} />
        </div>
      </Col>
      <Col sm="24">
        <div className="s-marginBottom3">
          <h3 className="s-marginBottom1 s-borderBottom1 u-colorGray2">
            Results
          </h3>
          {protocol.egl && (
            <GovernanceProposalShowEglResults
              proposal={proposal}
              results={results}
            />
          )}
          {!protocol.egl && (
            <GovernanceProposalShowResults
              proposal={proposal}
              results={results}
            />
          )}
        </div>
      </Col>
      <Col sm="24">
        <div className="s-marginBottom3">
          <h3 className="s-marginBottom1 s-borderBottom1 u-colorGray2">
            Content
          </h3>
          <GovernanceProposalShowContent proposal={proposal} />
        </div>
      </Col>
    </Grid>
  );
};

export default GovernanceProposalShow;
