import * as React from "react";
import { Grid, Col, Link, Button, Icon } from "@react/components";
import { pluralize } from "@react/utils/string";
export interface GovernanceProtocolsIndexProps {
  protocols: any[];
  governance_faq_path: string;
}

export const GovernanceProtocolsIndex: React.FunctionComponent<GovernanceProtocolsIndexProps> = ({
  protocols,
  governance_faq_path,
}) => {
  return (
    <Grid spacing="C">
      <Col sm="24">
        <h1 className="s-fontSize20 s-marginBottom2 u-fontWeight700 s-borderBottom1 u-displayFlex u-alignItemsCenter u-hiddenSmDown">
          <span className="s-marginRight0_5">
            <Icon icon="governance" />
          </span>
          Governance Protocols
          <span className="u-flex1 u-textAlignRight u-fontWeight400">
            <Link href={governance_faq_path} target="_blank">
              FAQs
            </Link>
          </span>
        </h1>
      </Col>
      {protocols.map((protocol) => (
        <Col sm="8" md="8" key={protocol.id}>
          <div className="s-marginBottom1">
            <Link unstyled href={protocol.path}>
              <div className="c-box s-padding1_5 u-textAlignCenter u-displayFlex u-alignItemsCenter">
                <div className="u-flex1">
                  <div className="c-avatar">
                    <img
                      className="governance-protocols-shared-sidebar__asset_logo"
                      alt={protocol.token_profile.name}
                      title={protocol.token_profile.name}
                      src={protocol.token_profile.logo_url}
                    />
                  </div>
                  <h2 className="s-fontSize22 u-fontWeight700 s-marginTop1">
                    {protocol.token_profile.name}
                  </h2>
                  <Grid spacing="C">
                    <Col sm="12">
                      <p className="u-textAlignCenter u-colorGray8 s-marginTop0_5 ">
                        {pluralize(protocol.proposals_count, "proposal")}
                      </p>
                    </Col>
                    <Col sm="12">
                      <p className="u-textAlignCenter u-colorGray8 s-marginTop0_5 ">
                        {pluralize(protocol.votes_count, "vote")}
                      </p>
                    </Col>
                  </Grid>
                  <div className="s-marginTop1_5">
                    <Button size="small" variant="gray">
                      View proposals
                    </Button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Col>
      ))}
    </Grid>
  );
};

export default GovernanceProtocolsIndex;
