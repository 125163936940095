import * as React from "react";
import { useState } from "react";
import { RangeInput } from "@react/components";
import RangeInputWithTooltip from "@react/views/shared/forms/RangeInputWithTooltip/RangeInputWithTooltip";

interface RangeInputSectionProps {
  // props
}

const RangeInputSection: React.FunctionComponent<RangeInputSectionProps> = (
  {
    // props
  }
) => {
  const [rangeInputValue1, setRangeInputValue1] = useState("0");
  const [rangeInputValue2, setRangeInputValue2] = useState("0");
  const [rangeInputValue3, setRangeInputValue3] = useState("0");
  const [rangeInputValue4, setRangeInputValue4] = useState("0");

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          RangeInput
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of{" "}
          <code className="c-code c-code--inline">{`<input type="range">`}</code>{" "}
          input
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With min, max, step and value
        </h3>
        <pre>
          <RangeInput
            min="-50"
            max="50"
            step="0.5"
            value={rangeInputValue1}
            onChange={(e) => setRangeInputValue1(e.target.value)}
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <RangeInput
            min="-50"
            max="50"
            step="0.5"
            value={rangeInputValue2}
            onChange={(e) => setRangeInputValue2(e.target.value)}
            disabled
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With tooltip
        </h3>
        <pre>
          <RangeInputWithTooltip
            min="-50"
            max="50"
            step="0.5"
            value={rangeInputValue3}
            onChange={(e) => setRangeInputValue3(e.target.value)}
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With tooltip disabled
        </h3>
        <pre>
          <RangeInputWithTooltip
            min="-50"
            max="50"
            step="0.5"
            value={rangeInputValue4}
            onChange={(e) => setRangeInputValue4(e.target.value)}
            disabled
          />
        </pre>
      </div>
    </div>
  );
};

export default RangeInputSection;
