import * as React from "react";
import { Tag, TagVariants } from "@react/components";

interface TagSectionProps {
  // props
}

const TagSection: React.FunctionComponent<TagSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Tag
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Implementation of our tag component
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <Tag>Default Tag</Tag>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Variants</h3>
        {TagVariants.map((variant, index) => (
          <div className="s-marginBottom1" key={`tag-variant-${index}`}>
            <pre>
              <Tag variant={variant}>{variant} Tag</Tag>
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagSection;
