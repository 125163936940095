import * as React from "react";
import {
  Grid,
  Col,
  Link,
  Icon,
  Button,
  Table,
} from "@react/components";
import * as moment from "moment";

export interface AdminGovernanceProtocolsShowProps {
  protocol: any;
  proposals: any[];
  admin_path: string;
  admin_governance_path: string;
  admin_governance_protocol_path: string;
  governance_path: string;
  governance_protocol_path: string;
}

export const GovernanceProtocolsShow: React.FunctionComponent<AdminGovernanceProtocolsShowProps> = ({
  protocol,
  proposals,
  admin_path,
  admin_governance_path,
  admin_governance_protocol_path,
  governance_path,
  governance_protocol_path,
}) => {
  const classByState = {
    created: "u-colorOrange",
    published: "u-colorGreen",
    closed: "u-colorGray8",
    hidden: "u-colorGrayA",
  };

  const sortedProposal = proposals
    .sort((a, b) => {
      return new Date(a.start_at).getTime() - new Date(b.start_at).getTime();
    })
    .reverse();

  const rows = sortedProposal.map((proposal) => {
    const endAt = moment(proposal.end_at);
    const startAt = moment(proposal.start_at);
    return [
      <Link href={proposal.admin_path}>{proposal.title}</Link>,
      <span className={classByState[proposal.state]}>{proposal.state}</span>,
      <>{endAt.format("MMMM Do YYYY")}</>,
      <>{startAt.format("MMMM Do YYYY")}</>,
      <span className="s-fontSize14 u-colorGray8">{proposal.id}</span>,
      <p className="u-textAlignRight">
        <Link href={proposal.path} target="_blank">
          View live page
        </Link>
      </p>,
    ];
  });

  return (
    <Grid>
      <Col sm="24">
        <h1 className="s-marginBottom3 s-borderBottom2">
          <Link href={admin_path}>
            <span className="u-whiteSpaceNowrap">Admin</span>
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            <Icon icon="chevron-right" />
          </span>
          <Link href={admin_governance_path}>
            <span className="u-whiteSpaceNowrap">Governance</span>
          </Link>
          <span className="s-paddingHoriz0_5 s-fontSize18 u-displayInlineBlock u-colorGrayA">
            <Icon icon="chevron-right" />
          </span>
          <span>{protocol.token_profile.name}</span>
          <span className="u-floatRight">
            <Link href={governance_protocol_path} unstyled target="_blank">
              <Button variant="secondary" size="small">
                {protocol.token_profile.name} page
              </Button>
            </Link>
          </span>
        </h1>
      </Col>
      <Col sm="24">
        <h3 className="s-marginBottom1 s-borderBottom1 u-colorGray2">
          Proposals
        </h3>
        <Table
          headers={["Title", "State", "Start at", "End at", "ID", ""]}
          headersGridSizes={[6, 3, 4, 4, 4, 3]}
          rows={rows}
        />
      </Col>
    </Grid>
  );
};

export default GovernanceProtocolsShow;
