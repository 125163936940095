import * as React from "react";
import {
  Grid,
  Col,
} from "@react/components";
import * as moment from "moment";

export interface GovernanceProposalShowEglResultsProps {
  proposal: any;
  results: Object;
}

export const GovernanceProposalShowEglResults: React.FunctionComponent<GovernanceProposalShowEglResultsProps> = ({
  proposal,
  results,
}) => {
  return (
    <Grid>
      {Object.keys(results).map((key) => (
        <Col sm="4">
          <div className="s-marginBottom2">
            <strong>{key}</strong>
            <br />
            Gas delta:
            <br />
            <strong>{results[key]}</strong>
          </div>
        </Col>
      ))}
    </Grid>
  );
};

export default GovernanceProposalShowEglResults;
