import * as React from "react";

export interface TableBodyProps {
  children?: React.ReactNode;
}

export const TableBody: React.FunctionComponent<TableBodyProps> = ({
  children,
}) => {
  return <tbody>{children}</tbody>;
};

export default TableBody;
