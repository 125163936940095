import * as React from "react";
import { useState } from "react";
import {
  Field,
  Select,
  Checkbox,
  TextArea,
  TextInput,
  Label,
  InlineLabel,
  Hint,
  Error,
} from "@react/components";
import RadioCollections from "@react/views/shared/forms/RadioCollections/RadioCollections";

interface FieldSectionProps {
  // props
}

const FieldSection: React.FunctionComponent<FieldSectionProps> = (
  {
    // props
  }
) => {
  const carOptions = [
    {
      labelText: "Toyota",
      value: "toyota",
    },
    {
      labelText: "Ford",
      value: "ford",
    },
    {
      labelText: "Peugeot",
      value: "peugeot",
    },
  ];

  const [radioValue, setRadioValue] = useState("toyota");
  const radioOptions = [
    {
      label: "Toyota",
      radio: {
        value: "toyota",
      },
    },
    {
      label: "Ford",
      radio: {
        value: "ford",
        disabled: true,
      },
    },
    {
      label: "Tesla",
      radio: {
        value: "tesla",
      },
    },
  ];

  const fieldInputExample = `<Field>
  <Label htmlFor="name">Name</Label>
  <TextInput name="name" placeholder="Jon" />
</Field>`;

  const fieldTextAreaHintExample = `<Field>
  <Label htmlFor="notes">Notes</Label>
  <TextArea name="notes" placeholder="..."/>
  <Hint>Less than 200 words</Hint>
</Field>`;

  const selectFieldCodeWithError = `<Field error={true}>
  <Label htmlFor="car-3">Car Brand</Label>
  <Select options={carOptions} name="car-3" />
  <Error>Found some unamerican brands</Error>
</Field>`;

  const fieldCheckboxHintExample = `<Field>
  <Label htmlFor="agreement">Agreement</Label>
  <InlineLabel>
    <Checkbox />
    I agree to ToS
  </InlineLabel>
  <Hint>Find our <a href="#">ToS here</a></Hint>
</Field>`;

  const fieldRadioCollectionExample = `<Field>
  <Label htmlFor="agreement">Pick a a car</Label>
  <RadioCollections
    radioOptions={radioOptions}
    name="car"
    value={radioValue}
    onChange={(e) => setRadioValue(e.target.value)}
  />
  <Hint>Electric is best</Hint>
</Field>`;

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Field
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          A field wrapper that typically will compose a series of{" "}
          <code className="c-code c-code--inline">{"<Label>"}</code>,{" "}
          <code className="c-code c-code--inline">{"<input>"}</code>,{" "}
          <code className="c-code c-code--inline">{"<Hint>"}</code> &{" "}
          <code className="c-code c-code--inline">{"<Error>"}</code>
        </p>
        <p className="s-marginBottom1 u-colorBlue">
          Note: Unless you want a different layout, use{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>{" "}
          instead of composing your own Field design.
        </p>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Field: Input
        </h3>
        <pre>
          <Field>
            <Label htmlFor="name">Name</Label>
            <TextInput name="name" placeholder="Jon" />
          </Field>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Field: TextArea & Hint
        </h3>
        <pre>
          <Field>
            <Label htmlFor="notes">Notes</Label>
            <TextArea name="notes" placeholder="..." />
            <Hint>Less than 200 words</Hint>
          </Field>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Field: Select & Error
        </h3>
        <pre>
          <Field error={true}>
            <Label htmlFor="car-3">Car Brand</Label>
            <Select options={carOptions} name="car-3" />
            <Error>Found some unamerican brands</Error>
          </Field>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Field: Checkbox with Hint
        </h3>
        <pre>
          <Field>
            <Label htmlFor="agreement">Agreement</Label>
            <InlineLabel>
              <Checkbox />I agree to ToS
            </InlineLabel>
            <Hint>
              Find our <a href="#">ToS here</a>
            </Hint>
          </Field>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Field: RadioCollections with Hint
        </h3>
        <pre>
          <Field>
            <Label htmlFor="agreement">Pick a a car</Label>
            <RadioCollections
              radioOptions={radioOptions}
              name="car"
              value={radioValue}
              onChange={(e) => setRadioValue(e.target.value)}
            />
            <Hint>Electric is best</Hint>
          </Field>
        </pre>
      </div>
    </div>
  );
};

export default FieldSection;
