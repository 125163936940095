import * as React from "react";

interface UtilitiesSectionProps {
  // props
}

const UtilitiesSection: React.FunctionComponent<UtilitiesSectionProps> = (
  {
    // props
  }
) => {
  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          CSS Framework & Utility classes
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          Coinlist uses a custom{" "}
          <a href="https://github.com/coinlist/css-framework" target="_blank">
            CSS framework
          </a>
          , it is worth familiarzing yourself with that codebase if you plan to
          build a lot of user-facing product.
        </p>
        <p className="s-marginBottom0_5 u-colorGray6">
          We have many utility classes, please refer to{" "}
          <a href="admin/coinlist/style">CoinList styles</a> for full
          documentation
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Utility: Spacing & border
        </h3>
        <pre>
          <div className="s-margin2 s-padding1 s-border1">
            This text has border, margin and padding
          </div>
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Utility: text size and font weight
        </h3>
        <pre>
          <p className="s-fontSize22 u-fontWeight800">
            This text is large and heavy
          </p>
        </pre>
      </div>

      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Utility: text and background color
        </h3>
        <pre>
          <p className="u-backgroundColorRed u-colorOrange s-padding1">
            This text is orange on red background with padding
          </p>
        </pre>
      </div>
    </div>
  );
};

export default UtilitiesSection;
