import * as React from "react";
import { Grid, Col } from "@react/components";
import { renderMarkdown } from "@react/utils/markdown";
export interface GovernanceProposalShowSettingsProps {
  proposal: any;
}

export const GovernanceProposalShowContent: React.FunctionComponent<GovernanceProposalShowSettingsProps> = ({
  proposal,
}) => {
  const prettifyMarkdown = proposal.content.replace(/(?:\r\n|\r|\n)/g, "<br>");
  return (
    <Grid>
      <Col sm="12">
        <div
          className="u-colorGray4 u-wordBreakAll"
          dangerouslySetInnerHTML={{ __html: prettifyMarkdown }}
        />
      </Col>
      <Col sm="12">
        <div
          className="u-colorGray4 proposal_card_markdown_content"
          dangerouslySetInnerHTML={{ __html: renderMarkdown(proposal.content) }}
        />
      </Col>
    </Grid>
  );
};

export default GovernanceProposalShowContent;
