import * as React from "react";
import { useState } from "react";

import Flex from "../../../components/Flex";
import InfoWarning from "../../../components/warnings/InfoWarning";
import { Link, Spacer } from "@react/components";
import Loading from "../../../components/Loading";
import MoreOptionsDialog from "./components/MoreOptionsDialog";
import OptInDialog from "./components/OptInDialog";
import StakeDialog from "../../../components/dialogs/StakeDialog";
import StakingBox from "../../../components/staking/StakingBox";
import StakingBoxMobile from "../../../components/staking/StakingBoxMobile";
import StakingSideBar from "../../../components/StakingSideBar";
import TableWithTitle from "@react/views/shared/TableWithTitle";
import Typography from "../../../components/typography/Typography";
import WithdrawDialog from "./components/UnstakeDialog";

import {
  appendQueryStringToCurrentUrl,
  isExtraSmallOrSmaller,
  caseInsensitiveStringComparison,
  roundFloatStr,
  createWalletCells,
  isTestEnv,
  rewardsPeriodParticipant,
} from "../../../utils";
import { getFormattedTime } from "../../../utils/date";
import { axiosRequest, RequestType } from "../../../utils/network";
import { StakingAsset } from "../../../types/staking";
import { LOGO_MAP } from "@react/shared/logos";
import { SegmentReact } from "@react/analytics/segment_react";

const T_EARLY_ADOPTERS_VAULT_APY = "~15.3% APY";
const T_NORMAL_VAULT_APY = "~12.75% APY";

const getDefaultStakingAsset = (
  defaultSymbol: string,
  stakingAssets: Array<StakingAsset>
): StakingAsset => {
  return defaultSymbol
    ? stakingAssets.find((asset) =>
      caseInsensitiveStringComparison(asset.symbol, defaultSymbol)
    )
    : stakingAssets[0];
};

const previousRewardsHeadCells = [
  "Principal",
  "Earnings",
  "Est. Annual Rewards",
  "Start Date",
  "End Date",
];

const autoStakingPreviousRewardsHeadCells = ["Earnings", "Date"];

const currentRewardsHeadCells = [
  "Principal",
  "Est. Annual Rewards",
  "Start Date",
  "End Date",
];

const handleCancel = async (url, symbol) => {
  try {
    const queryString = `?symbol=${symbol}`;
    await axiosRequest(url, {}, RequestType.POST);
    alert("Successfully cancelled deposit.");
    window.location.href = appendQueryStringToCurrentUrl(queryString);
  } catch (error) {
    alert("Error cancelling request, please try again.");
  }
};

const getCurrentRewardsRows = (allEarnings) => {
  return allEarnings.map((participant) => [
    <Typography fontSize={16}>
      {participant.second_starting_balance
        ? participant.starting_balance + participant.second_starting_balance.amount.to_money('AXL')
        : participant.starting_balance}
    </Typography>,
    /* For current rewards this will always be something '~32%' */
    /* temporary fix for current T early adopters vault being normal APY */
    <Typography fontSize={16}>
      {participant.apy == T_EARLY_ADOPTERS_VAULT_APY
        ? T_NORMAL_VAULT_APY
        : participant.apy}
    </Typography>,
    <Typography fontSize={16}>
      {getFormattedTime(participant.start_date)}
    </Typography>,
    <Typography fontSize={16}>
      {getFormattedTime(participant.end_date)}
    </Typography>,
  ]);
};

const getPreviousRewardsRows = (allEarnings) => {
  return allEarnings
    .map((participant) => [
      <Typography fontSize={16}>
        {participant.second_starting_balance
          ? participant.starting_balance + participant.second_starting_balance.amount.to_money('AXL')
          : participant.starting_balance}
      </Typography>,
      <Typography fontSize={16}>{participant.formatted_earnings}</Typography>,
      /* For past rewards this will always be something '32.235235' */
      <Typography fontSize={16}>
        ~{roundFloatStr(participant.apy, 2)}%
      </Typography>,
      <Typography fontSize={16}>
        {getFormattedTime(participant.start_date)}
      </Typography>,
      <Typography fontSize={16}>
        {getFormattedTime(participant.end_date)}
      </Typography>,
    ])
    .reverse();
};

const getAutoStakedPreviousRewardsRows = (allEarnings) => {
  return allEarnings.map((participant) => [
    <Typography fontSize={16}>{participant.formatted_earnings}</Typography>,
    <Typography fontSize={16}>
      {getFormattedTime(participant.end_date)}
    </Typography>,
  ]);
};

const initialUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    assetSymbolParams: urlParams.get("symbol"),
    openStakingRollover: Boolean(urlParams.get("openStakingRollover")),
    stake: Boolean(urlParams.get("stake")),
    unstake: Boolean(urlParams.get("unstake")),
  };
};

export default function Index(props) {
  const extraSmall = isExtraSmallOrSmaller();

  const [urlParams, setUrlParams] = useState(() => initialUrlParams());

  const [loading, setLoading] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<any>(null);

  const [stakingAssets, setStakingAssets] = useState<Array<StakingAsset>>(
    () => props.stakingMap
  );
  const selectedEntityId = props.selectedEntityId;
  const selectedEntity = props.entities.find(
    (entity) => entity.id === selectedEntityId
  );
  const [selected, setSelected] = useState<StakingAsset>(() =>
    getDefaultStakingAsset(urlParams.assetSymbolParams, stakingAssets)
  );

  /* Dialogs */
  const [open, setOpen] = useState(urlParams.stake && !selected.autoStaking);
  const [withdrawOpen, setWithdrawOpen] = useState(
    urlParams.unstake && !selected.autoStaking && !open
  );
  const [moreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const [optInOpen, setOptInOpen] = useState(
    urlParams.openStakingRollover && !open && !withdrawOpen
  );

  const allEarnings = isTestEnv()
    ? rewardsPeriodParticipant
    : selected.allEarnings;
  const currentlyLockedRewards = allEarnings.filter(
    (participant) => new Date(participant.end_date) > new Date()
  );
  const previousRewards = allEarnings.filter(
    (participant) => new Date() > new Date(participant.end_date)
  );
  const autoStakedPreviousRewards =
    selected.autoStaking && selected.symbol.toLowerCase() != "cspr"
      ? allEarnings.filter(
        (participant) => new Date() > new Date(participant.end_date)
      )
      : [];

  const acceptedWithdrawals =
    selected.pendingWithdrawals.length > 0
      ? selected.pendingWithdrawals.filter(
        (withdrawal) => withdrawal.state === "accepted"
      )
      : [];

  const getPendingBannerText = () => {
    let bannerText = "";
    if (selected.pendingDeposits.length > 0 && selected.startDate) {
      bannerText += `Pending deposits will be processed at the start of the next staking period (${getFormattedTime(
        selected.startDate
      )}). `;
    }
    if (acceptedWithdrawals.length > 0) {
      //TODO: Add unstakeDelay to this msg
      bannerText +=
        "Your pending withdrawal is being processed and will be completed once they are unstaked from the network. ";
    } else if (selected.pendingWithdrawals.length > 0 && selected.startDate) {
      bannerText += `Pending withdrawals will be processed at the end of the current period (${getFormattedTime(
        selected.startDate
      )}). `;
    }
    return bannerText;
  };

  const getTransactionRows = (pendingDeposits, pendingWithdrawals, symbol) => {
    const pendingDepositsRows = pendingDeposits.map((deposit) => [
      <Typography fontSize={16}>{"Deposit"}</Typography>,
      <Typography fontSize={16}>{deposit.created_at}</Typography>,
      <Typography fontSize={16}>{deposit.amount}</Typography>,
      <Link onClick={() => handleCancel(deposit.cancel_url, symbol)}>
        {"Cancel"}
      </Link>,
    ]);
    const pendingWithdrawalsRows = pendingWithdrawals.map((withdrawal) => [
      <Typography fontSize={16}>{"Withdrawal"}</Typography>,
      <Typography fontSize={16}>{withdrawal.created_at}</Typography>,
      <Typography fontSize={16}>{withdrawal.percent_to_withdraw}</Typography>,
      <Link onClick={() => setWithdrawOpen(true)}>Edit</Link>,
    ]);
    return pendingDepositsRows.concat(pendingWithdrawalsRows);
  };

  const moreOptionsOnClick = () => setMoreOptionsOpen(true);
  const stakeOnClick = () => {
    setSelected(selected);
    setOpen(true);
    SegmentReact.track(props.assetName + " Staking Page", {
      page_event: props.assetName + " Stake button clicked",
      user_id: props.userId,
      session_id: props.sessionId,
      viewed_at: new Date(),
    });
  };
  const unstakeOnClick = () => {
    setSelected(selected);
    setWithdrawOpen(true);
    SegmentReact.track(props.assetName + " Staking Page", {
      page_event: props.assetName + " Unstake button clicked",
      user_id: props.userId,
      session_id: props.sessionId,
      viewed_at: new Date(),
    });
  };

  let walletCells: Array<any> = createWalletCells(selected);

  const renderLoading = () => {
    return (
      <Flex container>
        <Loading
          handleResponse={(response) => {
            if (response.data.errors) {
              alert(response.data.errors.message);
              setSelected(stakingAssets[0]);
            } else {
              const {
                data: { stakingMap },
              } = response;
              const newStakingAssets = stakingMap;
              setStakingAssets(newStakingAssets);
              setSelected(newStakingAssets[0]);
            }
            setLoading(false);
          }}
          loading={loading}
          requestData={requestData}
        />
      </Flex>
    );
  };

  const renderPage = () => {
    const notificationBannerText = "The vault staking program is being discontinued for all assets. As usual, funds will be claimable at the end of the current staking periods. All vault deposit requests will be refunded by October 25.";
    return (
      <Flex container spacing={extraSmall ? 1 : 2}>
        <Flex item xs={24}>
          <InfoWarning>
            {notificationBannerText}
          </InfoWarning>
        </Flex>

        <Flex item sm={6} style={{ flexGrow: 0 }} xs={24}>
          <Flex container spacing={2}>
            {stakingAssets.map((asset) => (
              <StakingSideBar
                apy={asset.apy ? asset.apy * 100 : null}
                autoStaking={asset.autoStaking}
                assetSymbol={asset.symbol}
                fullWidth
                key={asset.symbol}
                logo={LOGO_MAP[asset.symbol.toLowerCase()]}
                onClick={() => {
                  setSelected(asset);
                  /* https://stackoverflow.com/questions/5007530/how-do-i-scroll-to-an-element-using-javascript */
                  document.getElementById("mainContainer").scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
                }}
                selected={selected.symbol === asset.symbol}
                stakedAmount={asset.currentlyStakedAmount}
              />
            ))}
          </Flex>
        </Flex>
        <Flex item sm={18} xs={24}>
          <Flex container spacing={2}>
            <Flex item xs={24}>
              {extraSmall ? (
                <StakingBoxMobile
                  asset={selected}
                  moreOptionsOnClick={moreOptionsOnClick}
                  stakeOnClick={stakeOnClick}
                  unstakeOnClick={unstakeOnClick}
                  walletCells={walletCells}
                  userId={props.userId}
                  sessionId={props.sessionId}
                />
              ) : (
                <StakingBox
                  asset={selected}
                  moreOptionsOnClick={moreOptionsOnClick}
                  stakeOnClick={stakeOnClick}
                  unstakeOnClick={unstakeOnClick}
                  walletCells={walletCells}
                  userId={props.userId}
                  sessionId={props.sessionId}
                  originPage={"Staking"}
                />
              )}
            </Flex>
            {selected.showRollover && (
              <Flex item xs={24}>
                <InfoWarning>
                  If you want to edit the percentage of{" "}
                  {selected.symbol.toUpperCase()} to stake once it unlocks,
                  click{" "}
                  <Link
                    onClick={() =>
                      (window.location.href = `?symbol=${selected.symbol}&openStakingRollover=true`)
                    }
                  >
                    here
                  </Link>
                  .
                </InfoWarning>
              </Flex>
            )}
            {!selected.autoStaking &&
              (!selected.depositsOn ||
                (!selected.withdrawalsOn && selected.stakedAmount > 0)) && (
                <Flex item xs={24}>
                  <InfoWarning>
                    {!selected.depositsOn && !selected.withdrawalsOn
                      ? "Deposits & withdrawals"
                      : !selected.depositsOn
                        ? "Deposits"
                        : "Withdrawals"}{" "}
                    are temporarily turned off.
                  </InfoWarning>
                </Flex>
              )}
            {selected.autoStaking && (
              <Flex item xs={24}>
                <InfoWarning>
                  Auto staked assets are automatically staked when they are
                  deposited in your CoinList Wallet.
                </InfoWarning>
              </Flex>
            )}
            {selected.description && (
              <Flex item xs={24}>
                <InfoWarning>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selected.description,
                    }}
                    style={{ display: "inline", fontSize: 13 }}
                  />
                </InfoWarning>
              </Flex>
            )}
            {currentlyLockedRewards.length > 0 && (
              <Flex item xs={24}>
                <TableWithTitle
                  headers={currentRewardsHeadCells}
                  rows={getCurrentRewardsRows(currentlyLockedRewards)}
                  title="Current Staking Periods"
                />
              </Flex>
            )}
            {previousRewards.length > 0 && !selected.autoStaking && (
              <Flex item xs={24}>
                <TableWithTitle
                  headers={previousRewardsHeadCells}
                  rows={getPreviousRewardsRows(previousRewards)}
                  title="Previous Staking Periods"
                />
              </Flex>
            )}
            {autoStakedPreviousRewards.length > 0 && (
              <Flex item xs={24}>
                <TableWithTitle
                  headers={autoStakingPreviousRewardsHeadCells}
                  rows={getAutoStakedPreviousRewardsRows(
                    autoStakedPreviousRewards
                  )}
                  title="Previous Rewards"
                />
              </Flex>
            )}
            {(selected.pendingDeposits.length > 0 ||
              selected.pendingWithdrawals.length > 0) &&
              !selected.autoStaking && (
                <Flex container>
                  <InfoWarning>{getPendingBannerText()}</InfoWarning>
                  <div style={{ width: "100%" }}>
                    <Spacer spacing={1} />
                  </div>
                  <TableWithTitle
                    headers={["Type", "Date Requested", "Amount", ""]}
                    rows={getTransactionRows(
                      selected.pendingDeposits,
                      selected.pendingWithdrawals,
                      selected.symbol
                    ).reverse()}
                    title="Pending Transactions"
                  />
                </Flex>
              )}
          </Flex>
        </Flex>
        {open && (
          <StakeDialog
            amountAvailable={selected.balance}
            amountAvailableFormattedStr={selected.balanceFormattedStr}
            amountAvailableErc20={selected.balanceErc20}
            amountAvailableFormattedStrErc20={selected.balanceFormattedStrErc20}
            assetSymbol={selected.symbol}
            depositUrl={selected.depositUrl}
            purchaseUrl={selected.purchaseUrl}
            interestRate={selected.apy}
            lockUpPeriod={parseInt(selected.lockUpPeriod)}
            minDeposit={selected.minDeposit}
            onClose={() => setOpen(false)}
            open={open}
            priceInUsd={selected.priceInUsd}
            startDate={selected.startDate}
            stxToUsdPrice={props.stxToUsdPrice}
            url={selected.stakeUrl}
            urlErc20={selected.stakeUrlErc20}
            userId={props.userId}
            sessionId={props.sessionId}
            originPage={"Staking"}
          />
        )}
        {withdrawOpen && (
          <WithdrawDialog
            assetSymbol={selected.symbol}
            endDate={selected.startDate}
            onClose={() => setWithdrawOpen(false)}
            open={withdrawOpen}
            stakedAmount={selected.stakedAmount}
            stakedAmountFormattedStr={selected.currentlyStakedAmount}
            unstakeDelay={selected.unstakeDelay}
            url={selected.withdrawUrl}
            userId={props.userId}
            sessionId={props.sessionId}
            originPage={"Staking"}
          />
        )}
        {moreOptionsOpen && (
          <MoreOptionsDialog
            assetSymbol={selected.symbol}
            autoStaked={selected.autoStaking}
            autoStakingOptOut={selected.autoStakingOptOut}
            changeAutoStake={(optOut: boolean, assetSymbol: string) => {
              const newAssets = [...stakingAssets];
              const updatedAsset = newAssets.find(
                (asset) => asset.symbol === assetSymbol
              );
              updatedAsset.autoStakingOptOut = optOut;
              setStakingAssets(newAssets);
            }}
            onClose={() => setMoreOptionsOpen(false)}
            open={moreOptionsOpen}
            selectedEntityId={selectedEntityId}
            url={props.toggleAutoStakingUrl}
          />
        )}
        {optInOpen && urlParams.assetSymbolParams && (
          <OptInDialog
            assetSymbol={urlParams.assetSymbolParams}
            interestRate={selected.apy}
            lockedFlowBalance={parseFloat(props.lockedFlowBalance)}
            lockUpPeriod={parseInt(selected.lockUpPeriod)}
            minDeposit={selected.minDeposit}
            onClose={() => setOptInOpen(false)}
            open={optInOpen}
            selectedEntityId={selectedEntityId}
            stakingRolloverOptInPercentage={
              props.stakingRolloverOptInPercentage
            }
            startDate={selected.startDate}
            url={props.updateStakingRolloverUrl}
          />
        )}
      </Flex>
    );
  };

  return loading ? renderLoading() : renderPage();
}
