import * as React from "react";
import { useState } from "react";
import { TextArea, TextAreaSizes } from "@react/components";

interface TextAreaSectionProps {
  // props
}

const TextAreaSection: React.FunctionComponent<TextAreaSectionProps> = (
  {
    // props
  }
) => {
  const [textAreaValue, setTextAreaValue] = useState("Value");

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          TextAreas
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          An implementation of{" "}
          <code className="c-code c-code--inline">{"<textarea>"}</code> input
        </p>
        <p className="s-marginBottom0_5 u-colorBlue">
          Note: If you want Label, Hint, Error, etc... use this component with{" "}
          <code className="c-code c-code--inline">
            <a href="#BlockField">{"<BlockField>"}</a>
          </code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Default</h3>
        <pre>
          <TextArea />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With placeholder
        </h3>
        <pre>
          <TextArea placeholder="Placeholder" />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          With value
        </h3>
        <pre>
          <TextArea
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Rows</h3>
        <pre>
          <TextArea
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            rows={5}
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Autoresize
        </h3>
        <pre>
          <TextArea
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            autoResize={true}
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Disabled</h3>
        <pre>
          <TextArea
            disabled
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
          />
        </pre>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">Sizes</h3>
        {TextAreaSizes.map((size, index) => (
          <div className="s-marginBottom1" key={`select-input-size-${index}`}>
            <pre>
              <TextArea placeholder={size} size={size} />
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextAreaSection;
