import * as React from "react";

import InfoBox from "./InfoBox";

export default function GovernanceInfo(props) {
  const algo = props.tokenProfile.symbol.toUpperCase() === "ALGO";
  return algo ? (
    <div></div>
  ) : (
    <InfoBox
      category="imx_governance"
      confirmationSubText={`We'll email you when ${props.tokenProfile.symbol.toUpperCase()} governance is live on CoinList.`}
      image={
        <i
          className={`icon-bank u-colorBrandSecondary`}
          style={{ fontSize: 50 }}
        ></i>
      }
      joinWaitlistUrl={props.joinWaitlistUrl}
      loggedInUser={props.loggedInUser}
      mainContent="Vote on proposals and shape the protocol. Coming soon to CoinList."
      onWaitlist={props.onGovernanceWaitlist}
      productName="governance"
      successMessage="Successfully joined the governance waitlist."
      title={"Governance"}
    />
  );
}
