import * as React from "react";
import { Divider } from "@react/components";

interface DividerSectionProps {
  // props
}

const DividerSection: React.FunctionComponent<DividerSectionProps> = (
  {
    // props
  }
) => {

  return (
    <div>
      <div className="s-marginBottom2">
        <h2 className="s-fontSize26 s-borderBottom0_5 s-marginBottom1 u-colorGray2">
          Divider
        </h2>
        <p className="s-marginBottom0_5 u-colorGray6">
          A component to help us with vertical spacing.
        </p>
        <p className="s-marginBottom0_5 u-colorGray6">
          Divider takes one argument,{" "}
          <code className="c-code c-code--inline">spacing</code>, which goes
          from <code className="c-code c-code--inline">1</code> to{" "}
          <code className="c-code c-code--inline">16</code> in increment of{" "}
          <code className="c-code c-code--inline">0.5</code>
        </p>
      </div>
      <div className="s-marginBottom2">
        <h3 className="s-fontSize22 s-marginBottom1 u-colorGray4">
          Default use
        </h3>
        <pre>
          <p>A Line of text</p>
          <Divider />
          <p>Another Line of text</p>
          <Divider spacingTop={0} spacingBottom={0} />
          <p>Yet another Line of text</p>
          <Divider spacingTop={4} spacingBottom={2.5} />
          <p>Last line of text</p>
        </pre>
      </div>
    </div>
  );
};

export default DividerSection;
