import * as React from "react";
import { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";

import { Button, Divider } from "@react/components";
import Confirmation from "@react/components/Confirmation";
import Flex from "@react/components/Flex";
import Loading, { RequestData } from "@react/components/Loading";
import Typography from "@react/components/typography/Typography";

import { RequestType } from "@react/utils/network";

const CONTAINER_PADDING = 20;

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: 300,
    maxWidth: 615,
    width: "100%",
  },
}));

interface DialogProps {
  onClose: () => void;
  open: boolean;
  placedLoanId: string;
  setPaymentsResponse: (boolean) => void;
  setSnackbarOpen: (boolean) => void;
  url: string;
}

export default function MakeFinalPaymentsDialog(props: DialogProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState<RequestData>(null);
  const [response, setResponse] = useState(null);

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      fullWidth
      onClose={props.onClose}
      open={props.open}
    >
      <Flex container style={{ padding: CONTAINER_PADDING }} spacing={1}>
        <Flex item xs={24}>
          <Typography type="h6">Terminate Loan</Typography>
        </Flex>
        <Flex item xs={24}>
          <Divider spacingTop={0} spacingBottom={0} />
        </Flex>
        {response && response.status === 200 ? (
          <Confirmation
            mainText={response.data.mainText}
            subText={response.data.subText}
          />
        ) : (
          <Flex container spacing={2}>
            <Typography bold>
              We've created the final payments that are still due. After you pay
              them, your loan will be terminated.
            </Typography>
            <Typography>
              Please make sure that you have enough funds in your wallet to
              cover the remaining payments. If you do, you can pay now.
            </Typography>
            <Flex
              container
              justifyContent="flex-end"
              spacing={2}
              style={{
                bottom: CONTAINER_PADDING,
                position: "absolute",
                right: CONTAINER_PADDING,
              }}
            >
              <Button onClick={() => props.onClose()} variant="secondary">
                Back
              </Button>
              <Button
                onClick={() => {
                  const data = {
                    placedLoanId: props.placedLoanId,
                  };
                  setRequestData({
                    data: data,
                    type: RequestType.POST,
                    url: props.url,
                  });
                  setLoading(true);
                }}
              >
                {loading ? (
                  <Loading
                    handleResponse={(response) => {
                      if (response.status !== 200) {
                        props.setSnackbarOpen(true);
                      }
                      setLoading(false);
                      props.setPaymentsResponse(response);
                    }}
                    loading={loading}
                    requestData={requestData}
                    spinnerColor="white"
                  />
                ) : (
                  "Pay now"
                )}
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Dialog>
  );
}
