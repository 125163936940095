import * as React from "react";
import { renderMarkdown } from "@react/utils/markdown";
import useLocalStorage from "@react/hooks/useLocalStorage";
import { SetValue } from "@react/hooks/useLocalStorage";

import GovernanceProposalEglGasLimitForm from "@react/views/governance/proposals/show/egl_gas_limit_form";
import GovernanceProposalQuiz from "@react/views/governance/proposals/show/quiz";

export interface GovernanceProposalContentProps {
  proposal: any;
  protocol: any;
  entity: any;
  entity_can_vote: boolean;
  currentVote?: string;
  setCurrentVote?: SetValue<string>;
}

const GovernanceProposalContent: React.FunctionComponent<GovernanceProposalContentProps> = ({
  proposal,
  protocol,
  entity,
  entity_can_vote,
  currentVote,
  setCurrentVote,
}) => {
  // Only show test to peeps who can vote
  const [showQuiz, setShowQuiz] = useLocalStorage(
    `governance_proposal_${proposal.id}_show_quiz`,
    entity_can_vote
  );

  return (
    <div className="c-box">
      <div className="s-padding1_5">
        <h3 className="s-fontSize26 u-fontWeight700 s-marginBottom1">
          {proposal.title}
        </h3>
        <div
          className="u-colorGray4 s-marginBottom1 proposal_card_markdown_content"
          dangerouslySetInnerHTML={{ __html: renderMarkdown(proposal.content) }}
        />
        {protocol.egl && (
          <>
            {!showQuiz && (
              <GovernanceProposalEglGasLimitForm
                proposal={proposal}
                entity={entity}
                entity_can_vote={entity_can_vote}
                currentVote={currentVote}
                setCurrentVote={setCurrentVote}
              />
            )}
            {showQuiz && (
              <GovernanceProposalQuiz
                successCallback={() => setShowQuiz(false)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GovernanceProposalContent;
